<template>
  <v-container class="pt-6 pa-0 ma-0">
    <!-- <v-sheet> -->

      <v-row>
        <!-- <v-spacer class="d-none d-md-flex"></v-spacer> -->

        <v-col cols="5">
          <v-row>
            <v-col xs="4" class="pa-1 ma-0" align="justified">
              <h4>Score</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="4" class="pa-0 ml-6" align="left">
              {{ teamName }}: <br /><v-chip class="ml-8">{{ scoreUs }}</v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="4" class="pa-0 ml-6" align="left">
              {{ opponent }}: <br /><v-chip class="ml-8">{{ scoreThem }}</v-chip>
            </v-col>
          </v-row>
          <v-row v-if="gameType">
            <v-col>
              Game Type: **<b>Scrimage</b>**
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="3">
          <v-row>
            <v-col xs="1" class="pa-1 ma-0">
              <h4>Time</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="1" class="pa-1 ma-1">
              <v-chip>{{ display }}</v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="1" class="pa-1 ma-1">
              <v-chip>{{ gamePeriod }}</v-chip>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-row>
            <v-col xs="1" class="pa-1 ma-0">
              <v-btn rounded="xl" small @click="start()" v-if="!isStarted" color="green" class="pa-1 ma-2" type="tonal">Start</v-btn>
              <v-btn rounded="xl" small @click="pause()" v-if="isStarted" color="amber" class="pa-1 ma-2" type="tonal">Pause</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="1" class="pa-1 ma-0">
              <v-btn rounded="xl" small @click="stop()" v-if="!isStarted" color="red" class="pa-1 ma-2" type="tonal">Reset</v-btn>
              <v-btn rounded="xl" small @click="halftime()" v-if="isStarted && gamePeriod !== '2nd Half' && gamePeriod !== 'Overtime' && gamePeriod !== 'Shootout'" color="blue" class="pa-1 ma-2" type="tonal">Halftime</v-btn>
              <v-btn rounded="xl" small @click="endSecondHalf()" v-if="(gamePeriod === '2nd Half' || gamePeriod === 'Overtime' || gamePeriod === 'Shootout') &&  isStarted" color="red" class="pa-1 ma-2" type="tonal">Period End</v-btn>
              <!-- <v-btn small @click="endGame()" v-if="gamePeriod === '2nd Half' && isStarted" color="red" class="pa-1 ma-2" type="tonal">End Game</v-btn> -->
            </v-col>
          </v-row>
        </v-col>

        <!-- <v-spacer class="d-none d-md-flex"></v-spacer> -->
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto" class="pa-1 ma-0">
              <h3 v-if="Paused">Time Paused</h3>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <v-dialog
      v-model="eorPrompt">
        <v-card>
          <v-card-text>
            End of game or overtime/shootout?
            <v-btn rounded="xl" small @click="overtime('Overtime')" v-if="isStarted" color="red" class="pa-1 ma-2" type="tonal">Overtime Period</v-btn>
            <v-btn rounded="xl" small @click="overtime('Shootout')" v-if="isStarted" color="red" class="pa-1 ma-2" type="tonal">Shootout</v-btn>
            <v-btn rounded="xl" small @click="endGame()" v-if="isStarted" color="red" class="pa-1 ma-2" type="tonal">End Game</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- </v-sheet> -->
  </v-container>
</template>

<script setup lang="ts">
import { getGameData, toggleGameTime } from '@/composables/backend'
import { ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue'
import { useGameState } from '../store/index'
const store = useGameState()

// const timeUnitSeconds = [
//   1,
//   60,
//   60 * 60,
//   24 * 60 * 60
// ]

// const convertTimeFromString = (string = '') => {
//   const timeUnits = string.split(':').reverse()
//   let seconds = 0
//   let isNotNaN = false
//   timeUnits.forEach((unit, index) => {
//     if (timeUnitSeconds[index] && !isNaN(unit)) {
//       seconds += unit * timeUnitSeconds[index]
//       isNotNaN = true
//     }
//   })
//   if (isNotNaN) return seconds
//   else return NaN
// }

const display = ref('00:00:00')
const displayms = ref('0')
let d = 0
let h = 0
let m = 0
let s = 0
const percentage = ref(0)
const defaultSeconds = 0
const isStarted = ref(false)
const isStopped = ref(true)
const countingUp = true
const targetTimestamp = ref(store.lastTimeStamp || 0)
const pausedDifference = ref(store.lastTimeStamp || 0)
// let isBlinking = false
// interval: () => {},
const interval = ref(0)
const Paused = ref(false)
const halftimestamp = ref('')
let saveitter = 0

const gamePeriod = ref('Not Started')
const scoreUs = ref(0)
const scoreThem = ref(0)
const opponent = ref(store.Opponent)
const teamName = ref(store.TeamName)
const gameType = ref(false)

const eorPrompt = ref(false)

const start = async () => {
  const gts = await toggleGameTime(store, 'start')
  store.updateLastTimestamp(gts.GameTimestamp)
  const nowTimestamp = Date.now()
  let targetTime = defaultSeconds
  if (pausedDifference) {
    targetTime = pausedDifference.value
    pausedDifference.value = 0
  }
  if (countingUp) targetTimestamp.value = nowTimestamp - targetTime
  else targetTimestamp.value = nowTimestamp + targetTime
  interval.value = setInterval(() => {
    if (!countingUp && targetTimestamp.value - Date.now() < 1) {
      stop()
      updateDisplay(0)
      // isBlinking = true
      // console.log(isBlinking)
    } else updateDisplay()
  }, 10)
  isStarted.value = true
  isStopped.value = false
  // isBlinking.value = false
  // console.log(isBlinking)
  Paused.value = false
  store.changeGameInProgress(true)
  if (store.timePaused) {
    store.setGamePaused(false)
  }
  if (store.GamePeriod === '' || store.GamePeriod === 'Not started') {
    store.setGamePeriod('1st Half')
    gamePeriod.value = store.GamePeriod
  } else if (store.GamePeriod === 'Halftime') {
    store.setGamePeriod('2nd Half')
    gamePeriod.value = store.GamePeriod
  }
}

const halftime = () => {
  pause()
  store.setHalfTime(display.value)
  store.setGamePeriod('Halftime')
  gamePeriod.value = store.GamePeriod
  halftimestamp.value = display.value
}

const pause = async () => {
  const gts = await toggleGameTime(store, 'pause')
  store.updateLastTimestamp(gts.GameTimestamp)
  const nowTimestamp = Date.now()
  pausedDifference.value = Math.abs(targetTimestamp.value - nowTimestamp)
  clearInterval(interval.value)
  updateDisplay()
  isStarted.value = false
  store.setGamePaused(true)
  Paused.value = true
}

const stop = () => {
  if (isStarted.value) pause()
  reset()
  updateDisplay()
  isStopped.value = true
}

const reset = () => {
  const nowTimestamp = Date.now()
  if (countingUp) targetTimestamp.value = nowTimestamp - defaultSeconds
  else targetTimestamp.value = nowTimestamp + defaultSeconds
  pausedDifference.value = 0
}

const updateDisplay = (targetTime) => {
  // Update both display (hh:mm:ss and miliseconds)
  const nowTimestamp = Date.now()
  if (targetTime !== undefined) {
    if (countingUp) targetTimestamp.value = nowTimestamp - targetTime
    else targetTimestamp.value = nowTimestamp + targetTime
  }
  const newS = Math.abs(Date.now() - targetTimestamp.value) / 1000
  const ms = Math.floor((s % 1) * 1000)
  s = Math.floor(newS % 60)
  m = Math.floor(newS / 60) % 60
  h = Math.floor(newS / 3600) % 24
  d = Math.floor(newS / 86400)
  let newDisplay = s.toString().padStart(2, '0')
  newDisplay = m.toString().padStart(2, '0') + ':' + newDisplay
  newDisplay = h.toString().padStart(2, '0') + ':' + newDisplay
  if (d) newDisplay = d.toString().padStart(2, '0') + ':' + newDisplay
  display.value = newDisplay

  displayms.value = ('000' + ms).slice(-3)
  if (!countingUp && defaultSeconds) {
    percentage.value = s * 1000 / defaultSeconds
  }
  // save game time in the store every 5 seconds
  saveitter++
  if (saveitter >= 500) {
    store.updateGameTime(display.value)
    store.updateLastTimestamp(Math.abs(targetTimestamp.value - nowTimestamp))
    saveitter = 0
  }
}

// const countup = () => {
//   // Switch to counting up
//   countingUp = true
//   const nowTimestamp = Date.now()
//   const difference = Math.abs(targetTimestamp.value - nowTimestamp)
//   targetTimestamp.value = nowTimestamp - difference
//   // updateDisplay()
// }

// const countdown = () => {
//   // Switch to counting down
//   percentage = 0
//   countingUp = false
//   const nowTimestamp = Date.now()
//   const difference = Math.abs(targetTimestamp.value - nowTimestamp)
//   targetTimestamp.value = nowTimestamp + difference
//   // this.updateDisplay()
// }

const endSecondHalf = async () => {
  eorPrompt.value = !eorPrompt.value
}

const overtime = async (t) => {
  eorPrompt.value = !eorPrompt.value

  store.setGamePeriod(t)

  const gameData = {
    current_game_time: store.GameTime,
    last_timestamp: store.lastTimeStamp,
    our_score: store.scoreUs,
    their_score: store.scoreThem,
    assists: store.Assists,
    our_shots: store.ourShots,
    their_shots: store.theirShots,
    game_final: 0,
    game_period: t
  }
  const gameDataJson = JSON.stringify(gameData)

  fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveGameData/' + store.OrgID + '/' + store.TeamID + '/' + store.GameID, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: gameDataJson
  })
  // stop the clock
  pause()
}

const endGame = async () => {
  eorPrompt.value = !eorPrompt.value
  store.changeGameInProgress(false)
  store.setGamePeriod('Final')
  const gameData = {
    current_game_time: store.GameTime,
    last_timestamp: store.lastTimeStamp,
    our_score: store.scoreUs,
    their_score: store.scoreThem,
    assists: store.Assists,
    our_shots: store.ourShots,
    their_shots: store.theirShots,
    game_final: 1,
    game_period: 'Final'
  }
  const gameDataJson = JSON.stringify(gameData)

  fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveGameData/' + store.OrgID + '/' + store.TeamID + '/' + store.GameID, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: gameDataJson
  })
  // stop the clock
  pause()
}

const updateFromStore = async () => {
  gamePeriod.value = store.GamePeriod
  display.value = store.GameTime

  scoreUs.value = store.scoreUs
  scoreThem.value = store.scoreThem
}

watch(store, () => {
  updateFromStore()
})

onMounted(async () => {
  const data = await getGameData(store)

  // set store data if it's not set
  if (store.Opponent !== data.opponent) {
    store.setOpponent(data.opponent)
  }

  if (store.GamePeriod !== data.game_period) {
    store.setGamePeriod(data.game_period || 'Not started')
  }

  if (store.lastTimeStamp !== data.last_timestamp) {
    store.updateLastTimestamp(data.last_timestamp)
  }
  if (store.GameTime !== data.current_game_time) {
    store.updateGameTime(data.current_game_time || '00:00:00')
  }

  if (store.timePaused !== data.timePaused) {
    store.setGamePaused(data.timePaused || false)
  }

  gameType.value = data.scrimage

  updateFromStore()
  opponent.value = store.Opponent
  teamName.value = store.TeamName
  targetTimestamp.value = store.lastTimeStamp
  pausedDifference.value = store.lastTimeStamp

  if (store.GamePeriod === 'Not started' && store.lastTimeStamp > 0) {
    store.setGamePeriod('1st Half')
  }

  if (store.lastTimeStamp > 0) {
    if ((!store.timePaused) && (!data.game_final)) {
      // console.log('Time should start!')
      start()
      Paused.value = false
      halftimestamp.value = store.halfTime
    } else {
      // console.log('Time paused!')
      Paused.value = true
    }
  }
})

// const retime = () => {
//   // To change the time
//   const { value: input } = $swal.fire({
//     input: 'text',
//     inputValue: defaultSeconds / 1000,
//     showCancelButton: true,
//     inputValidator: (input) => {
//       const inputSecs = convertTimeFromString(input)
//       console.log(inputSecs)
//       if (Number.isNaN(Number(inputSecs))) {
//         return 'Enter a valid value!'
//       } else if (inputSecs < 0) {
//         return 'Enter a positive number!'
//       }
//     }
//   })
//   if (!input) return
//   defaultSeconds = convertTimeFromString(input) * 1000
//   updateDisplay(defaultSeconds)
//   if (pausedDifference.value) pausedDifference.value += defaultSeconds
// }

// const globalClick = () => {
//   isBlinking.value = false
// }
</script>
