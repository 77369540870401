<template>

<div>
  <v-container fluid>
    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-card-title>Team Season Stats</v-card-title>
          <v-card-text>
            Record: {{record}}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-row>
            <v-col>
              <v-card-title>Goals</v-card-title>
              <v-data-table
                :headers="goalHeaders"
                :items="goals"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-col>
            <v-col>
              <v-card-title>Assists</v-card-title>
              <v-data-table
                :headers="assistHeaders"
                :items="assists"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">

          <v-row>
            <v-col>
              <v-card-title>Time in all games</v-card-title>
              <v-data-table
                :headers="tigHeaders"
                :items="timeInGame"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-col>
            <v-col>
              <v-card-title>Games Played</v-card-title>
              <v-data-table
                :headers="gpHeaders"
                :items="gamesPlayed"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

<v-btn @click="showplayer">test</v-btn>

    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-card-title>Time in Positions</v-card-title>
            <v-data-table
              :headers="tipHeaders"
              :items="timeInPosition"
              class="elevation-1 py-0 px-0"
              :items-per-page="15"
              mobile-breakpoint="0"
            ></v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showPlayer"
    >
      <PlayerStats :Player="p"/>
    </v-dialog>

  </v-container>

</div>

</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted } from 'vue-demi'
import PlayerStats from '../components/PlayerStats.vue'

const store = useGameState()
const route = useRoute()

const record = ref('0-0-0')
const timeInGame = ref([])
const timeInPosition = ref([])
const gamesPlayed = ref([])
const assists = ref([])
const goals = ref([])
const goalHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Goals', key: 'Goals', align: 'start', sortable: true }
]
const assistHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Assists', key: 'Assists', align: 'start', sortable: true }
]
const tigHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Minutes', key: 'Timeingame', align: 'start', sortable: true }
]
const gpHeaders = [
  { text: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { text: 'Games', key: 'GamesPlayed', align: 'start', sortable: true }
]
// turn into a computed property that gets the proper formation position names
// perhaps use current formation (/api/getGameFormation/{gameid})
const tipHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Striker', key: 'Time_p9', sortable: true },
  { title: 'Left Mid', key: 'Time_p8', sortable: true },
  { title: 'Center Mid', key: 'Time_p7', sortable: true },
  { title: 'Right Mid', key: 'Time_p6', sortable: true },
  { title: 'Stopper', key: 'Time_p5', sortable: true },
  { title: 'Left Backer', key: 'Time_p4', sortable: true },
  { title: 'Right Backer', key: 'Time_p3', sortable: true },
  { title: 'Sweeper', key: 'Time_p2', sortable: true },
  { title: 'Goalie', key: 'Time_p1', sortable: true },
  { title: 'Total in Game', key: 'Timeingame', sortable: true },
  { title: 'Total Bench Time', key: 'Timeonbench', align: 'start', sortable: true }
]

const showPlayer = ref(false)
const p = ref({})


const showplayer = () => {
  showPlayer.value = !showPlayer.value
}


const getTeamStats = async () => {
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getTeamStats/' + store.OrgID + '/' + store.TeamID).then((res) => res.json())
  
  // console.log(data)

  record.value = data.Record

  timeInGame.value = data.Stats[0].Stats
  timeInPosition.value = data.Stats[0].Stats
  gamesPlayed.value = data.Stats[0].Stats
  assists.value = data.Stats[0].Stats
  goals.value = data.Stats[0].Stats

  // hard coded for now
  p.value = data.Stats[0].Stats[0]
}

onMounted(() => {
  getTeamStats()
  store.setActivePage(route.name)
})

</script>
