<template>

<div>
  <v-container fluid>
    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-card-title>Team Game Stats vs {{ Opponent }}</v-card-title>
          <v-card-text>
            Score: {{score}}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-sheet color="background">
          <v-row>
            <v-col>
              <v-card>
              <v-card-title>Goals</v-card-title>
              <v-data-table
                :headers="goalHeaders"
                :fixed-header=true
                :items="goals"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-card>
            </v-col>
            <v-col>
              <v-card>
              <v-card-title>Assists</v-card-title>
              <v-data-table
                :headers="assistHeaders"
                :fixed-header=true
                :items="assists"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-card>
            </v-col>
          </v-row>
        </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-row>
            <v-col>
              <v-card-title>Shots/Fouls/Cards/etc.</v-card-title>
              <v-data-table
                :headers="statHeaders"
                :fixed-header=false
                :items="timeInPosition"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="admin">
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-sheet color="background">
          <v-row>
            <v-col>
              <v-card>
              <v-card-title>Time in Positions</v-card-title>
              <v-data-table
                :headers="tipHeaders"
                :fixed-header=true
                :items="timeInPosition"
                class="elevation-1 py-0 px-0"
                :items-per-page="15"
                mobile-breakpoint="0"
              ></v-data-table>
            </v-card>
            </v-col>
          </v-row>
        </v-sheet>
        </v-card>
      </v-col>
    </v-row>

  </v-container>

</div>

</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted } from 'vue-demi'
import { getPerms } from '../composables/userPerms'
import { getGameData } from '../composables/backend'
const store = useGameState()
const route = useRoute()

const score = ref('0-0')
const timeInPosition = ref([])
const assists = ref([])
const goals = ref([])
const goalHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Goals', key: 'Goals', align: 'start', sortable: true }
]
const assistHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Assists', key: 'Assists', align: 'start', sortable: true }
]
const tipHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Striker', key: 'Time_p9', sortable: true },
  { title: 'Left Mid', key: 'Time_p8', sortable: true },
  { title: 'Center Mid', key: 'Time_p7', sortable: true },
  { title: 'Right Mid', key: 'Time_p6', sortable: true },
  { title: 'Stopper', key: 'Time_p5', sortable: true },
  { title: 'Left Backer', key: 'Time_p4', sortable: true },
  { title: 'Right Backer', key: 'Time_p3', sortable: true },
  { title: 'Sweeper', key: 'Time_p2', sortable: true },
  { title: 'Goalie', key: 'Time_p1', sortable: true },
  { title: 'In Game Time', key: 'Timeingame', align: 'start', sortable: true },
  { title: 'Total Bench Time', key: 'Timeonbench', align: 'start', sortable: true }
]
const Opponent = ref('')
const statHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Shots', key: 'ShotsTaken', align: 'start', sortable: true },
  { title: 'Shots on Goal', key: 'Shotsongoal', align: 'start', sortable: true },
  { title: 'Saves', key: 'Saves', align: 'start', sortable: true },
  { title: 'Goals allowed', key: 'ScoredAgainst', align: 'start', sortable: true },
  { title: 'Steals', key: 'Steals', align: 'start', sortable: true },
  { title: 'Turnovers', key: 'UnforcedErrors', align: 'start', sortable: true },
  { title: 'Offsides', key: 'Offsides', align: 'start', sortable: true },
  { title: 'Fouls', key: 'Fouls', align: 'start', sortable: true },
  { title: 'Yellow Cards', key: 'Yellowcards', align: 'start', sortable: true },
  { title: 'Red Cards', key: 'Redcards', align: 'start', sortable: true }
]
const admin = ref(false)

const getTeamStats = async () => {
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameStats/' + store.OrgID + '/' + store.TeamID + '/' + store.GameID).then((res) => res.json())

  timeInPosition.value = data
  filterNoAssists(data)
  filterNoGoals(data)
}

const filterNoGoals = (d) => {
  const newdata = []
  for (let n = 0; n < d.length; n++) {
    if (d[n].Goals > 0 && d[n].Playerid !== '') {
      newdata.push(d[n])
    }
  }
  goals.value = newdata
}
const filterNoAssists = (d) => {
  const newdata = []
  for (let n = 0; n < d.length; n++) {
    if (d[n].Assists > 0 && d[n].Playerid !== '') {
      newdata.push(d[n])
    }
  }
  assists.value = newdata
}

const getGameInfo = async () => {
  const data = await getGameData(store)

  Opponent.value = data.opponent
  score.value = data.our_score + ' - ' + data.their_score
}

const checkPermissions = async () => {
  const perms = await getPerms(store)

  // console.log(perms)

  if (perms.RBAC === 'Viewer') {
    admin.value = false
  } else {
    admin.value = true
  }
}

onMounted(async () => {
  await checkPermissions()
  await getTeamStats()
  store.setActivePage(route.name)
  await getGameInfo()
})

</script>
